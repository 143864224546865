import { A, cache, createAsync, useNavigate, useParams } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  onMount,
  Show,
} from "solid-js";
import { closeIcon } from "~/assets/assets";
import { ButtonDark } from "~/components/gifting/verification/components";
import { GiftCardBack } from "~/components/pluto/gift-card/gift_card_back";
import { GiftCardFront } from "~/components/pluto/gift-card/gift_card_front";
import { usePluto } from "~/context/pluto_context";
import { clientRepo } from "~/server/apis/client_repo";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { GiftCardOccasion } from "~/server/types/pluto";
import { goBack } from "~/shared_states/modal";
import { showSnackBar } from "~/shared_states/snackbar";
import { createJob } from "~/utils/job";
import { PhosphorIcon } from "~/widgets/icons";

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

export type GiftCardOccasionWithImage = GiftCardOccasion & {
  occasionImageId?: string;
};

export default function EditGiftPage() {
  const navigate = useNavigate();
  const [updatedTextFields, setUpdatedTextFields] =
    createSignal<GiftCardOccasionWithImage | null>(null);

  const params = useParams();

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  const { plutoGifts, updateGiftCardDetails } = usePluto();

  const [latestGiftCardDetails, setLatestGiftCardDetails] = createSignal(
    plutoGifts?.newGiftCardDetails ?? routeData()?.giftDetails
  );

  onMount(async () => {
    const giftDetails = await clientRepo.getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  const giftCardDetails = createMemo(() => {
    return latestGiftCardDetails() ?? routeData()?.giftDetails;
  });

  const updateGiftCardsDataJob = createJob({
    initialJob: async () => {
      await updateGiftCardOccasion();
      await updateGiftCardSelection();
    },
    successCallback: async () => {
      goBack();
    },
    errorCallback: (error) => {
      showSnackBar(error.message);
    },
  });

  const selectionData = createMemo(() => ({
    titleId: giftCardDetails()?.content.selection.titleId ?? "1",
    activities: giftCardDetails()?.content.selection.activities ?? {
      "1": "1",
      "2": "1",
      "3": "1",
    },
    activityImageId:
      giftCardDetails()?.content.selection.activityImageId ??
      giftCardDetails()?.content.images.activityImages?.[0]?.id ??
      "",
    occasionImageId:
      giftCardDetails()?.content.selection.occasionImageId ??
      giftCardDetails()?.content.images.occasionImages?.[0]?.id ??
      updatedTextFields()?.occasionImageId ??
      "",
  }));

  const [updatedSelection, setUpdatedSelection] = createSignal(selectionData());

  createEffect(() => {
    if (giftCardDetails()) {
      setUpdatedSelection(selectionData());
    }
  });

  const updateGiftCardOccasion = async () => {
    if (updatedTextFields()) {
      const { occasionImageId, ...occasionDetails } = updatedTextFields()!;

      await clientRepo.updateGiftOccasion(params.giftId, updatedTextFields()!);
      updateGiftCardDetails("content.text.occasion", occasionDetails);
    }
  };

  const updateGiftCardSelection = async () => {
    await clientRepo.updateGiftSelection(params.giftId, {
      ...updatedSelection(),
      occasionImageId:
        updatedTextFields()?.occasionImageId ??
        giftCardDetails()?.content.selection.occasionImageId ??
        "",
    });

    updateGiftCardDetails("content.text.selection", {
      ...updatedSelection(),
      occasionImageId:
        updatedTextFields()?.occasionImageId ??
        giftCardDetails()?.content.selection.occasionImageId ??
        "",
    });
  };

  return (
    <div class="flex h-full w-full flex-col md:w-[800px] lg:w-screen">
      <div class="relative flex min-h-14 items-center justify-center bg-plutoHeaderBg bg-cover bg-no-repeat px-4 py-2">
        <img
          src={closeIcon}
          alt=""
          class={`absolute left-2 top-2 cursor-pointer`}
          onClick={() => {
            goBack();
          }}
        />
        <p class="text-center text-smallBold uppercase tracking-[1.1px] text-white">
          Editing gift
        </p>
      </div>
      <div class="no-scrollbar mx-auto overflow-y-auto lg:flex lg:w-[766px] lg:gap-4">
        <div>
          <div class="mt-6 flex flex-col items-center justify-center">
            <p class="text-h3 text-white">Brands & experiences</p>
            <p class="text-medium text-textNormal">
              Tap the item to make changes
            </p>
          </div>
          <div class="mb-7 mt-2 p-4 lg:mt-2">
            <div class="min-h-[560px] w-[343px] rounded-3xl bg-plutoGiftCard">
              <Show when={giftCardDetails()}>
                <GiftCardBack
                  giftDetails={() => giftCardDetails()!}
                  isEditable={true}
                  onUpdate={setUpdatedSelection}
                />
              </Show>
            </div>
          </div>
        </div>

        <div>
          <div class="flex flex-col items-center justify-center gap-1 lg:mt-6">
            <p class="text-h3 text-white">Wish & the message</p>
            <p class="text-medium text-textNormal">
              Tap the item to make changes
            </p>
          </div>
          <div class="my-6 mt-2 p-4 lg:mt-2">
            <div class="min-h-[560px] w-[343px] rounded-3xl bg-plutoGiftCard">
              <Show when={giftCardDetails()}>
                <GiftCardFront
                  giftDetails={() => giftCardDetails()!}
                  isEditable={true}
                  onUpdate={setUpdatedTextFields}
                />
              </Show>
            </div>
          </div>
        </div>
      </div>
      {buildBottomActions()}
    </div>
  );

  function buildBottomActions() {
    return (
      <div class="border-t border-basePrimaryDark p-4 lg:w-screen">
        <div class="flex w-full items-center justify-center gap-3 lg:mx-auto lg:w-[766px] lg:justify-end lg:gap-4">
          <A
            href={giftCardDetails()?.receiversPreviewUrl ?? ""}
            target="_blank"
            class="flex h-11 w-full flex-1 items-center  justify-center rounded-full border border-baseSecondaryDark text-buttonMedium lg:h-11 lg:max-w-[200px]"
          >
            <p class="text-nowrap text-buttonMedium text-white">
              Receiver’s preview
            </p>
          </A>
          <ButtonDark
            isLoading={() => updateGiftCardsDataJob.jobState() === "running"}
            class="flex w-full flex-1 !rounded-full border border-transparent lg:h-11 lg:max-w-[200px]"
            onClick={() => {
              updateGiftCardsDataJob.run();
            }}
          >
            Save
          </ButtonDark>
        </div>
      </div>
    );
  }
}
